// import styled from "styled-components";
import * as styled from "styled-components";
import { createGlobalStyle, css } from "styled-components";
// import reset from "styled-reset";
import "./variables.css";

const constants = {
  buttonBorderRadius: "4px",
  colors: {
    black: "#000000",
    candyApple: "#e5293b",
    cloudy: "#f0f5f7",
    coolBlue: "#0e4f66",
    crimson: "#c72b35",
    deepLake: "#163547",
    gravel: "#565656",
    wetCement: "#77848e",
    white: "#ffffff",
    gray: {
      900: "#999999",
    },
    red: "#ff0000",
    limegreen: "#D0FF94",
  },
  dark: {
    backgroundColor: "#0f172a", // "#052147", //"#0b2c59",
    color: "#94a3b8",
    linkColor: "#38bdf8",
    linkHoverColor: "red", // "#e050a0", //"#ffffc1",
  },
  light: {
    backgroundColor: "#f8f8f9", // #f2f2f2",
    color: "#000",
    linkColor: "#0ea5e9", //"#157bd1",
    linkHoverColor: "#00a6ff", //"#0e75c9", //"#63ffda",
  },
};

export const StyleGuide = {
  // export const StyleGuide = () => {
  // let styleGuide = {
  buttons: {
    primary: {
      name: "Button",
      backgroundColor: "#0ea5e9",
      borderColor: "",
      borderRadius: constants.buttonBorderRadius,
      className: "button",
      color: "#ffffff",
      fontSize: "1rem",
      fontWeight: "500",
      padding: "14px 28px",
      tagName: "button",
      textTransform: "uppercase",
      transition: "all 0.4s",
      hover: {
        backgroundColor: "#e050a0",
        borderColor: "",
        borderRadius: constants.buttonBorderRadius,
        color: "#ffffff",
        fontSize: "1rem",
        fontWeight: "500",
        padding: "14px 28px",
        transition: "all 0.4s",
      },
    },
    disabled: {
      name: "Button",
      backgroundColor: "#9e9e9e",
      borderColor: "",
      borderRadius: constants.buttonBorderRadius,
      className: "button-disable",
      color: "#ffffff",
      fontSize: "1rem",
      fontWeight: "500",
      padding: "14px 28px",
      tagName: "button",
      transition: "all 0.4s",
      hover: {
        backgroundColor: "#9e9e9e",
        borderColor: "",
        borderRadius: constants.buttonBorderRadius,
        color: "#ffffff",
        fontSize: "1rem",
        fontWeight: "500",
        padding: "14px 28px",
        transition: "all 0.4s",
      },
    },
    outline: {
      name: "Button",
      backgroundColor: "#ffffff",
      border: "1px solid #0ea5e9",
      borderColor: "#0ea5e9",
      borderRadius: constants.buttonBorderRadius,
      borderWidth: "1px",
      className: "button-outline",
      color: "#3e3e3e",
      fontSize: "1rem",
      fontWeight: "500",
      padding: "14px 28px",
      tagName: "button",
      transition: "all 0.4s",
      hover: {
        backgroundColor: "#0ea5e9",
        borderColor: "",
        borderRadius: constants.buttonBorderRadius,
        color: "#ffffff",
        fontSize: "1rem",
        fontWeight: "500",
        padding: "14px 28px",
        transition: "all 0.4s",
      },
    },
  },
  colorPalette: {
    primary: [
      {
        name: "Primary 1",
        hexValue: "#0ea5e9",
        rgbValue: "",
        text: "#000",
        type: "primary",
      },
      {
        name: "Primary 2",
        // name: "Yep, it's pink",
        hexValue: "#e050a0", // "#f472b6"
        rgbValue: "",
        text: "#000",
        type: "primary",
      },
      {
        name: "Primary 3",
        hexValue: "#1966b2",
        rgbValue: "",
        text: "#000",
        type: "primary",
      }, // #1c77c3
      {
        borderColor: "#3a4458",
        borderStyle: "solid",
        borderWidth: "1px",
        name: "Primary 4",
        hexValue: "#172234",
        /*"#1e293c",*/ rgbValue: "",
        text: "#fff",
        type: "primary",
      },
      {
        borderColor: "#3a4458",
        borderStyle: "solid",
        borderWidth: "1px",
        name: "Primary 5",
        hexValue: "#1a2438",
        rgbValue: "",
        text: "#fff",
        type: "primary",
      },
      {
        name: "Primary 6",
        hexValue: "#ffffff",
        rgbValue: "",
        text: "#000",
        type: "primary",
      },
    ],
    secondary: [
      {
        name: "Secondary 1",
        hexValue: "#0f172a", //"#18181b",
        rgbValue: "",
        text: "#fff",
        type: "secondary",
      },
      {
        name: "Secondary 2",
        hexValue: "#052147",
        rgbValue: "",
        text: "#fff",
        type: "secondary",
      },
      // { name: "", hexValue: "#0b2c59", rgbValue: "", type: "secondary" },
      {
        name: "Secondary 3",
        hexValue: "#94a3b8",
        rgbValue: "",
        text: "#000",
        type: "secondary",
      },
      {
        name: "Secondary 4",
        hexValue: "#999999",
        rgbValue: "",
        text: "#000",
        type: "secondary",
      },
      // { name: "", hexValue: "#0049ad", rgbValue: "", type: "secondary" },
      {
        name: "Secondary 5",
        hexValue: "#efefef",
        rgbValue: "",
        text: "#000",
        type: "secondary",
      },
      {
        name: "Secondary 6",
        hexValue: "#f8f8f9",
        rgbValue: "",
        text: "#000",
        type: "secondary",
      },
    ],
    tertiary: [
      { name: "", hexValue: "#efefef", rgbValue: "", type: "tertiary" },
    ],
  },
  formsAndFields: [
    {
      id: "textField",
      placeholder: "First Name",
      name: "textField",
      type: "text",
      value: "",
    },
    {
      id: "textField2",
      placeholder: "Last Name",
      name: "textField2",
      type: "text",
      value: "",
    },
    {
      id: "checkboxChecked",
      placeholder: "",
      name: "checkboxChecked",
      type: "checkbox",
      value: "",
      checked: true,
    },
    {
      id: "checkboxUnchecked",
      placeholder: "",
      name: "checkboxUnchecked",
      type: "checkbox",
      value: "",
      checked: false,
    },
  ],
  typography: {
    h1: {
      name: "Header 1",
      fontSize: "44px",
      fontFamily: "Roboto",
      fontWeight: "500",
      lineHeight: "50px",
      tagName: "h1",
    },
    h2: {
      name: "Header 2",
      fontSize: "36px",
      fontFamily: "Roboto",
      fontWeight: "500",
      lineHeight: "42px",
      tagName: "h2",
    },
    h3: {
      name: "Header 3",
      fontSize: "28px",
      fontFamily: "Roboto",
      fontWeight: "500",
      lineHeight: "34px",
      tagName: "h3",
    },
    h4: {
      name: "Header 4",
      fontSize: "22px",
      fontFamily: "Roboto",
      fontWeight: "500",
      lineHeight: "28px",
      tagName: "h4",
    },
    h5: {
      name: "Header 5",
      fontSize: "18px",
      fontFamily: "Roboto",
      fontWeight: "500",
      lineHeight: "24px",
      tagName: "h5",
    },
    h6: {
      name: "Header 6",
      fontSize: "14px",
      fontFamily: "Roboto",
      fontWeight: "500",
      lineHeight: "20px",
      tagName: "h6",
    },
    body: {
      name: "Body",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "500",
      lineHeight: "20px",
      tagName: "body",
    },
  },
};
//   return styleGuide;
// };

export const theme = {
  color: {
    white: "#ffffff",
    black: "#000000",
    gray: {
      900: "#999999",
    },
    red: "#ff0000",
    limegreen: "#D0FF94",
  },
  font: {
    sans: "Roboto, Inter, Cambay, sans-serif",
    serif: "Arvo, sans",
    monospace: '"Ubuntu Mono", monospace',
  },
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "16px",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  fontWeight: {
    hairline: "100",
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
  letterSpacing: {
    normal: "normal",
    wide: "3px",
  },
  lineHeight: {
    normal: "1.5",
    tall: "2.2",
  },
  // link: {
  //     color: '#459bf1',
  //     textDecoration: 'none',
  // },
  // linkHover: {
  //     color: '#63ffda',
  //     textDecoration: 'underline',
  // },
  darkMode: {
    backgroundColor: constants.dark.backgroundColor, //"#0b2c59", //"rgb(22 32 51)", // '#0a192f'
    brandColor: "#fff",
    primaryColor: StyleGuide.colorPalette.primary[5].hexValue, // "hsla(0, 0%, 100%, 0.8)",
    secondaryColor: StyleGuide.colorPalette.secondary[2].hexValue, // "#ffffb1",
    supplementalColor: "#0ea5e9",
    link: {
      color: constants.dark.linkColor, // "#38bdf8", //"#459bf1",
      secondaryColor: "#0049ad",
      textDecoration: "none",
    },
    linkHover: {
      color: constants.dark.linkHoverColor,
      textDecoration: "underline",
    },
    headerBackgroundColor: constants.dark.backgroundColor, // "rgba(11, 44, 89, 0.5)", //rgba(81,83,147,0.5), //"#0b2c59", //"#1b3c69",
    landingPageTitle: "#000",
    pageTitle: StyleGuide.colorPalette.primary[5].hexValue, // constants.dark.linkColor, //"#459bf1",
  },
  lightMode: {
    backgroundColor: constants.light.backgroundColor,
    brandColor: "#000",
    primaryColor: "hsla(0, 0%, 0%, 0.8)",
    secondaryColor: StyleGuide.colorPalette.primary[2].hexValue, // "#1966b2", // '#79B6F2'
    supplementalColor: "#0ea5e9",
    link: {
      color: constants.light.linkColor,
      textDecoration: "none",
    },
    linkHover: {
      color: constants.light.linkHoverColor,
      textDecoration: "underline",
    },
    headerBackgroundColor: constants.light.backgroundColor, //"rgba(11, 44, 89, 1)",
    landingPageTitle: "#000",
    pageTitle: StyleGuide.colorPalette.primary[3].hexValue, // constants.light.linkColor, //"#459bf1",
  },
  styleGuide: StyleGuide,
};

// const GlobalStyle = createGlobalStyle`${css`
const GlobalStyle = styled.createGlobalStyle`
  /* ----------- Non-Retina Screens ----------- */

  /* ----------- Retina Screens ----------- */
  @media screen and (prefers-color-scheme: dark) {
    body {
      background-color: ${theme.darkMode.backgroundColor};
      color: ${theme.darkMode.primaryColor};
    }
  }

  /* Creates a one column layout for smaller screen sizes */
  @media (max-width: 800px) {
    .flex {
      //   flex-direction: column;
    }
    .contact-message {
      display: none;
    }
  }

  /* ----------- Tablet ----------- */
  /* Tablet */
  @media screen and (max-width: 768px) {
    .ham-icon {
      display: block;
    }
    .menu-container,
    .toggle-container {
      display: none;
    }
  }

  /* Tablet */
  @media screen and (min-width: 768px) {
    .ham-icon {
      display: none;
    }
  }

  /* ----------- iPhone X ----------- */
  /* Portrait and Landscape */
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    .ham-icon {
      display: block;
    }
    .contact-message {
      display: none;
    }
  }

  .ham-icon {
    // display: none;
  }

  // Reset and remove inherited styles;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    // box-sizing: inherit;
  }

  * {
    margin: 0;
    padding: 0;
    border: 0;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${theme.font.sans};
    // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: ${theme.fontSize.base};
    font-weight: ${theme.fontWeight.normal};
  }

  body {
    background-color: ${theme.lightMode.backgroundColor};
    color: ${theme.lightMode.primaryColor};
    line-height: ${theme.lineHeight.normal};
    letter-spacing: ${theme.letterSpacing.normal};
    // height: 100vh;
  }

  body::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  body::-webkit-scrollbar-track {
    background: ${({ theme }) =>
      theme?.darkMode.backgroundColor}; /* color of the tracking area */
    border-left: 1px solid #4f4f4f;
  }

  body::-webkit-scrollbar-thumb {
    background-color: #0d356d; /* color of the scroll thumb */
    border-radius: 0px; /* roundness of the scroll thumb */
    border: 1px solid #3f6f9f; /* creates padding around scroll thumb */
  }

  a {
    color: ${theme.lightMode.link.color};
    text-decoration: ${theme.lightMode.link.textDecoration};
  }

  a:hover {
    color: ${theme.lightMode.linkHover.color};
    text-decoration: ${({ theme }) =>
      theme?.lightMode.linkHover.textDecoration};
  }

  h1 {
    font-size: ${theme.styleGuide.typography.h1.fontSize};
    line-height: ${theme.styleGuide.typography.h1.lineHeight};
    font-weight: ${theme.styleGuide.typography.h1.fontWeight};
  }
  h2 {
    font-size: ${theme.styleGuide.typography.h2.fontSize};
    line-height: ${theme.styleGuide.typography.h2.lineHeight};
    font-weight: ${theme.styleGuide.typography.h2.fontWeight};
  }
  h3 {
    font-size: ${theme.styleGuide.typography.h3.fontSize};
    line-height: ${theme.styleGuide.typography.h3.lineHeight};
    font-weight: ${theme.styleGuide.typography.h3.fontWeight};
  }
  h4 {
    font-size: ${theme.styleGuide.typography.h4.fontSize};
    line-height: ${theme.styleGuide.typography.h4.lineHeight};
    font-weight: ${theme.styleGuide.typography.h4.fontWeight};
  }
  h5 {
    font-size: ${theme.styleGuide.typography.h5.fontSize};
    line-height: ${theme.styleGuide.typography.h5.lineHeight};
    font-weight: ${theme.styleGuide.typography.h5.fontWeight};
  }
  h6 {
    font-size: ${theme.styleGuide.typography.h6.fontSize};
    line-height: ${theme.styleGuide.typography.h6.lineHeight};
    font-weight: ${theme.styleGuide.typography.h6.fontWeight};
  }

  // header {
  //     position: sticky;
  //     top: 0;
  //     z-index: 100;
  // }

  p {
    margin-bottom: 1.45rem;
  }

  .bold {
    font-weight: 700;
  }
  .br-6 {
    border-radius: 6px;
  }
  .card {
    // background-color: ${theme.styleGuide.colorPalette.primary[4].hexValue};
    border-radius: 40px;
    box-shadow: 10px 44px 44px rgba(0, 0, 0, 0.1);
    padding: 60px 40px;
    z-index: 1;
  }

  // .card {
  //     background-color: #efefef;
  //     border-radius: 20px;
  //     box-shadow: 10px 44px 44px rgba(0, 0, 0, 0.1);
  //     padding: 60px 0 60px;
  //     // margin: 0 100px;
  //     opacity: 1;
  // }

  .container {
    color: ${theme.lightMode.primaryColor};
  }

  .block {
    display: block;
  }
  .hidden {
    display: none;
  }

  /* Flexbox */
  .flex {
    display: flex;
  }
  .flex-50 {
    flex-basis: 50%;
  }
  .flex-100 {
    flex-basis: 100%;
  }
  .flex-item {
    flex: 1;
    // margin: auto;
    // border: 1px solid red;
  }
  .flex-1-1-30 {
    flex: 1 1 25%;
  }
  .wrap {
    flex-wrap: wrap;
  }
  .align-start {
    align-items: flex-start;
  }
  .align-center {
    align-items: center;
  }
  .stretch {
    align-items: stretch;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-start {
    justify-content: flex-start;
  }
  .space-around {
    justify-content: space-around;
  }
  .space-evenly {
    justify-content: space-evenly;
  }
  .space-between {
    justify-content: space-between;
  }
  .column {
    flex-direction: column;
  }
  .flex-3-col {
    flex: 1 1 30%;
  }
  .flex-full-width {
    flex: 1 0 100%;
  }
  .fifty-fifty {
    display: flex;
    flex-wrap: wrap;
  }
  .fifty-fifty > * {
    flex-grow: 1;
    flex-basis: 50%;
    // flex-basis: 250px;
  }

  .fs-14 {
    font-size: 14px;
  }

  /* Grid */
  .grid {
    display: grid;
  }

  .gradient {
    background: linear-gradient(to right, #516af3, #e050a0); // #3ec7e0, #e050a0
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .button,
  .button-disable,
  .button-outline,
  .button-sm,
  .gradient-button,
  .gradient-button-disable {
    background-color: ${theme.styleGuide.buttons.primary.backgroundColor};
    // background: linear-gradient(to right, #516af3, #38bdf8);
    border-radius: ${theme.styleGuide.buttons.primary.borderRadius};
    color: ${theme.styleGuide.buttons.primary.color};
    font-size: ${theme.styleGuide.buttons.primary.fontSize};
    font-weight: ${theme.styleGuide.buttons.primary.fontWeight};
    padding: ${theme.styleGuide.buttons.primary.padding};
    // text-transform: ${theme.styleGuide.buttons.primary.textTransform};
    transition: ${theme.styleGuide.buttons.primary.transition};
  }
  .button-disable,
  gradient-button-disable {
    background-color: ${theme.styleGuide.buttons.disabled.backgroundColor};
  }
  .button-outline {
    background-color: ${theme.styleGuide.buttons.outline.backgroundColor};
    border: ${theme.styleGuide.buttons.outline.border};
    color: ${theme.styleGuide.buttons.outline.color};
  }
  .button:hover,
  .button-sm:hover,
  .gradient-button:hover {
    background-color: ${theme.styleGuide.buttons.primary.hover.backgroundColor};
    color: ${theme.styleGuide.buttons.primary.hover.color};
    // background: linear-gradient(to left, #516af3, #e050a0);
    transition: ${theme.styleGuide.buttons.primary.hover.transition};
    // transition: all 0.4s;
  }
  .button-outline:hover {
    background-color: ${theme.styleGuide.buttons.outline.hover.backgroundColor};
    color: ${theme.styleGuide.buttons.outline.hover.color};
    transition: ${theme.styleGuide.buttons.outline.hover.transition};
  }
  .button-sm {
    border-radius: 8px;
    font-size: 0.85rem;
    padding: 8px 16px;
  }

  .wrapper {
    margin: 0 auto;
    padding: 0;

    // max-width: 100%;
    overlow: hidden;
    width: 100%;
    // height: 100vh;
    // border: 2px solid orange;
  }

  .main-content {
    min-height: 100vh;
    margin: 0 auto 50px auto;

    // overlow: hidden;
    // width: 100%;

    max-width: 1280px;
    // padding: 80px 1.0875rem 1.45rem 0;
  }

  .m-1 {
    margin: 1rem;
  }
  .m-1-5 {
    margin: 1.5rem;
  }
  .m-2 {
    margin: 2rem;
  }
  .m-3 {
    margin: 3rem;
  }
  .m-4 {
    margin: 4rem;
  }
  .m-20 {
    margin: 20px;
  }
  .mb-0_5 {
    margin-bottom: 0.5rem;
  }
  .mb-1 {
    margin-bottom: 1rem;
  }
  .mb-2 {
    margin-bottom: 2rem;
  }
  .mb-3 {
    margin-bottom: 3rem;
  }
  .mb-4 {
    margin-bottom: 4rem;
  }
  .mb-5 {
    margin-bottom: 5rem;
  }
  .ml-1 {
    margin-left: 1rem;
  }
  .ml-2 {
    margin-left: 2rem;
  }
  .ml-3 {
    margin-left: 3rem;
  }
  .ml-4 {
    margin-left: 4rem;
  }
  .ml-5 {
    margin-left: 5rem;
  }
  .mr-1 {
    margin-right: 1rem;
  }
  .mr-2 {
    margin-right: 2rem;
  }
  .mr-3 {
    margin-right: 3rem;
  }
  .mr-4 {
    margin-right: 4rem;
  }
  .mr-5 {
    margin-right: 5rem;
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .mt-2 {
    margin-top: 2rem;
  }
  .mt-80 {
    margin-top: 80px;
  }

  .mlr-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mlr-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mlr-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .p-2 {
    padding: 2rem;
  }
  .p-20 {
    padding: 20px;
  }
  .page-container {
    margin: 0 auto;
    padding: 120px 0 0 0;
    width: 100%;
  }
  .page-title {
    display: inline-block;
    font-size: 28px;
    font-weight: 300;
    line-height: 1rem;
    // font-size: 1.5rem;
    margin-left: 20px;
  }
  .page-title-line {
    display: inline-block;
    background-color: ${theme?.lightMode.supplementalColor};
    // border: 1px solid ${theme?.lightMode.supplementalColor};
    height: 2px;
    margin-bottom: 10px;
    // position: static;
    width: 150px;
  }

  // .canvas .container {
  //     position: absolute;
  //     animation: stars linear infinite;
  //     z-index: 1;
  //     opacity: .5;
  // }

  // .canvas .container .star {
  //     border-radius: 100%;
  // }

  // .canvas .container:nth-child(n) {
  //     width: 3px;
  //     height: 3px;
  //     left: 30vw;
  //     animation-delay: -993.8s;
  //     animation-duration: 10s;
  // }

  // .canvas .container:nth-child(n) .star {
  //     width: inherit;
  //     height: inherit;
  //     background: #efefef;
  //     // animation-delay: 93.8s;
  //     // animation-duration: 15s;
  // }

  // @keyframes stars {
  //     0% {
  //         transform: translateY(110vh) translateZ(0);
  //     }
  //     100% {
  //         transform: translateY(-10vh) translateZ(0) scale(2);
  //     }
  // }

  .dark {
    background-color: ${theme?.darkMode.backgroundColor};
    color: ${theme.darkMode.primaryColor};
    transition: all 0.6s ease;
  }

  .light {
    background-color: ${theme.lightMode.backgroundColor};
    color: ${theme.lightMode.primaryColor};
    transition: all 0.6s ease;
  }

  input,
  textarea {
    outline: none;
    border-image-source: linear-gradient(
      rgba(0, 51, 102, 0.5),
      rgba(0, 0, 51, 0.5)
    );
    border-width: 5pt;
    border-image-slice: 1;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-label {
    color: #cecece;
  }

  .light .form-label {
    color: #27272a;
  }

  .form-control {
    display: block;
    width: 100%;
    // height: calc(1.5rem + 0.75rem + 2px);
    // padding: 0.75rem 0.75rem;
    font-size: 0.85rem;
    font-weight: 300;
    line-height: 1.5;
  }
  .form-input {
    // border-image-source: linear-gradient(to right, red, yellow) 1;
    border-radius: 8px;
    // border-width: 2px;
    background-color: rgba(23, 27, 30, 0.5);
    // background: #15417e;
    border: 1px solid #bebebe;
    // border: 2px solid #4d5261;
    // border-bottom: 1px solid #13aff0;
    color: #7e7e7e;
    // color: #aeaeae;
    // padding: 10px 10px 10px 6px;
    padding: 0.75rem;
  }
  .select {
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;

    select,
    &::after {
      grid-area: select;
    }

    min-width: 15ch;
    // max-width: 30ch;

    // border: 1px solid var(--select-border);
    // border-radius: 0.25em;
    // padding: 0.25em 0.5em;

    // font-size: 1.25rem;
    cursor: pointer;
    // line-height: 1.1;

    // Optional styles
    // remove for transparency
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

    // Custom arrow
    &:not(.select--multiple)::after {
      content: "";
      justify-self: end;
      width: 0.8em;
      height: 0.5em;
      background-color: var(--select-arrow);
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    }
  }

  // select {
  //   /* Reset styles along with removing the default arrow */
  //   appearance: none;
  //   background-color: transparent;
  //   border: none;
  //   cursor: inherit;
  //   font-family: inherit;
  //   font-size: inherit;
  //   line-height: inherit;
  //   margin: 0;
  //   outline: none;
  //   padding: 0 1em 0 0;
  //   width: 100%;
  //   z-index: 1;

  //   // background-image: linear-gradient(45deg, transparent 50%, #aeaeae 50%),
  //   //   linear-gradient(135deg, #aeaeae 50%, transparent 50%),
  //   //   linear-gradient(to right, transparent, transparent) !important;
  //   // background-position: calc(100% - 20px) calc(1em + 6px),
  //   //   calc(100% - 15px) calc(1em + 6px), 100% 0 !important;
  //   // background-size: 5px 5px, 5px 5px, 2.5em 2.5em !important;
  //   // background-repeat: no-repeat !important;
  // }
  // .select {
  //   width: 100%;
  //   min-width: 15ch;
  //   max-width: 30ch;
  //   border: 1px solid var(--select-border);
  //   border-radius: 0.25em;
  //   padding: 0.25em 0.5em;
  //   font-size: 1.25rem;
  //   cursor: pointer;
  //   line-height: 1.1;
  //   // background-color: #fff;
  //   background-image: linear-gradient(to top, #f9f9f9, #efefef 33%);

  //   display: grid;
  //   grid-template-areas: "select";
  //   align-items: center;

  //   // background-color: #999 !important;
  //   // // background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  //   // border: 1px solid var(--select-border);
  //   // color: #000;
  //   // width: 100%;
  // }
  // /* Arrow */
  // .select::after {
  //   color: #555;
  //   content: "";
  //   width: 0.8em;
  //   height: 0.5em;
  //   background-color: #000; // var(--select-arrow);
  //   clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  //   grid-area: select;
  //   justify-self: end;
  // }
  .form-input:focus {
    border-color: ${theme?.lightMode.supplementalColor}; // #47F4CF;
    // background-color: #cecece;
    // background-color: #0ea5e92e; // rgba(71,244,207,0.18);
  }
  ::placeholder {
    color: #7e7e7e;
  }

  .dark .form-input {
    background: transparent;
    // color: #414144;
  }

  .light .form-input {
    background: #fff;
    // color: #414144;
  }

  #website,
  .website {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }

  textarea.form-control {
    font-family: inherit;
    height: 8rem;
  }

  .error {
    // border: 1px solid red;
    color: #f10000;
    display: block;
    font-size: 12px;
    font-weight: 300;
    height: 20px;
    padding: 4px 2px;
  }

  .line {
    height: 2px;
  }

  .line:hover {
    border-top: 1px solid ${theme?.lightMode.supplementalColor};
  }

  .center {
    text-align: center;
  }

  .upper {
    text-transform: uppercase;
  }

  .form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    align-items: center;
    // justify-content: space-between;
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    // align-items: center;
    // justify-content: space-around;
  }

  .col,
  .col1,
  .col2,
  .col3,
  .col4,
  .col5,
  .col6,
  .col7,
  .col8,
  .col9,
  .col10,
  .col11,
  .col12 {
    padding: 0 15px;
    width: 100%;
    max-width: 100%;
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
  }

  .col1 {
    flex: 0 0 8.3333%;
  }

  .col2 {
    flex: 0 0 16.67%;
  }

  .col3 {
    flex: 0 0 25%;
  }

  .col4 {
    flex: 0 0 33.33%;
  }

  .col5 {
    flex: 0 0 41.66%;
  }

  .col6 {
    flex: 0 0 50%;
  }

  .col7 {
    flex: 0 0 58.33%;
  }

  .col8 {
    flex: 0 0 66.67%;
  }

  .col9 {
    flex: 0 0 75%;
  }

  .col10 {
    flex: 0 0 83.33%;
  }

  .col11 {
    flex: 0 0 91.67%;
  }

  .col12 {
    flex-basis: 0 0 100%;
  }

  .space {
    padding: 0 6px;
  }
  .w-100 {
    width: 100%;
  }

  // Animations
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
export default GlobalStyle;
// export { GlobalStyle };
