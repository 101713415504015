exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-recipes-tsx": () => import("./../../../src/pages/recipes.tsx" /* webpackChunkName: "component---src-pages-recipes-tsx" */),
  "component---src-pages-repos-tsx": () => import("./../../../src/pages/repos.tsx" /* webpackChunkName: "component---src-pages-repos-tsx" */),
  "component---src-pages-skills-tsx": () => import("./../../../src/pages/skills.tsx" /* webpackChunkName: "component---src-pages-skills-tsx" */),
  "component---src-pages-style-guide-tsx": () => import("./../../../src/pages/style-guide.tsx" /* webpackChunkName: "component---src-pages-style-guide-tsx" */)
}

