import React from "react";
import { ThemeProvider } from "./src/components/AppContext";
import GlobalStyle from "./src/theme/global-style";
import { theme } from "./src/theme/global-style";

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <div className="wrapper">{element}</div>
  </ThemeProvider>
);
