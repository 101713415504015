import { wrapRootElement as wrap } from "./root-wrapper"
import { logger } from "./src/components/Logger";

// import Layout from "./src/components/layout"

// Logs when the client route changes
  export const onRouteUpdate = ({ location, prevLocation }) => {
  logger.log("new pathname", location.pathname);
  logger.log("old pathname", prevLocation ? prevLocation.pathname : null);
};

export const wrapRootElement = wrap
