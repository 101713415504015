import React, { Component, createContext } from "react";
import { logger } from "./Logger";

// export const LOG_LEVEL: any = process.env.GATSBY_LOG_LEVEL ? process.env.GATSBY_LOG_LEVEL : "debug";
// export const LOG_LEVEL: LogLevel = "debug";
// export const LOG_LEVEL: LogLevel = process.env.GATSBY_LOG_LEVEL ? process.env.GATSBY_LOG_LEVEL : "debug";

const defaultContext = {
  dark: process.env.GATSBY_DEFAULT_DARK_MODE === "true",
//   logLevel: process.env.GATSBY_LOG_LEVEL ? process.env.GATSBY_LOG_LEVEL : "debug",
  toggleDark: () => {},
  theme: {},
};

const AppContext = createContext(defaultContext);

// const supportsDarkMode = () =>
//   window.matchMedia("(prefers-color-scheme: dark)").matches === true

// TODO: look at making this into a generic provider
class ThemeProvider extends Component {
  // class ThemeProvider extends Component<{}, { dark: boolean }> {
  constructor(props: any) {
    logger.log("ThemeProvider.constructor.props: ", props);

    super(props);
    this.state = {
      // dark: process.env.GATSBY_DEFAULT_DARK_MODE,
      dark: false,
      theme: props.theme,
    };
  }

  toggleDark = (theme: string) => {
    let dark = !this.state.dark;
    logger.log("AppContext.toggleDark.theme: ", theme);
    logger.log("AppContext.toggleDark.dark: ", dark);
    localStorage.setItem("dark", JSON.stringify(dark));
    this.setState({ dark });

    // TODO: this belongs in the consumer, not the context!
    // let x = document.getElementsByClassName("skill-icon")
    // for (let i = 0; i < x.length; i++) {
    //   if (dark) {
    //     x[i].classList.remove("skill-icon-dark")
    //   } else {
    //     x[i].classList.add("skill-icon-dark")
    //   }
    // }

    // x = document.getElementsByClassName("skill")
    // for (let i = 0; i < x.length; i++) {
    //   if (dark) {
    //     x[i].classList.remove("skill-light")
    //   } else {
    //     x[i].classList.add("skill-light")
    //   }
    // }

    // x = document.getElementsByClassName("point")
    // for (let i = 0; i < x.length; i++) {
    //   if (dark) {
    //     x[i].classList.remove("point-light")
    //   } else {
    //     x[i].classList.add("point-light")
    //   }
    // }
  };

  componentDidMount() {
    const isDark = JSON.parse(localStorage.getItem("dark") || "{}");
    logger.log("AppContext.componentDidMount.isDark: ", JSON.stringify(isDark));
    if (isDark) {
      logger.log("if - isDark: " + isDark);
      this.setState({ dark: isDark });
    } else {
      logger.log("else - isDark: " + isDark);
      this.setState({ dark: this.state.dark });
    }
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          toggleDark: this.toggleDark,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppContext;
export { ThemeProvider };
