import { Component } from "react";
// import { LOG_LEVEL } from "./AppContext";

interface LogFunction {
  (message?: any, ...optionalParams: any[]): void;
}

interface IConsoleLogger {
  log: LogFunction;
  warn: LogFunction;
  error: LogFunction;
}

export type LogLevel = "debug" | "warn" | "error";

interface IProps {
  level?: LogLevel;
}

export const LOG_LEVEL: any = process.env.GATSBY_LOG_LEVEL ? process.env.GATSBY_LOG_LEVEL : "debug";

const NO_OPERATION: LogFunction = (message?: any, ...optionalParams: any[]) => {};

class ConsoleLogger extends Component implements IConsoleLogger {
  constructor(props: IProps) {
    super(props);

    const { level } = props || {};
    // console.log("\n ConsoleLogger.level: ", level);

    switch (level) {
      case "error":
        // console.log("\n ConsoleLogger.case.error: ");
        this.error = console.error.bind(console);
        this.warn = NO_OPERATION;
        this.log = NO_OPERATION;
        break;
      case "warn":
        // console.log("\n ConsoleLogger.case.warn: ");
        this.error = console.error.bind(console);
        this.warn = console.warn.bind(console);
        this.log = NO_OPERATION;
        break;
      default:
        // console.log("\n ConsoleLogger.case.default: ");
        this.error = console.error.bind(console);
        this.warn = console.warn.bind(console);
        this.log = console.log.bind(console);
        break;
    }
    // console.log("\n ConsoleLogger.this.log: ", this.log);
  }

  log(message?: any, ...optionalParams: any[]): void {
    console.log(message, ...optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]): void {
    console.warn(message, ...optionalParams);
  }

  error(message?: any, ...optionalParams: any[]): void {
    console.error(message, ...optionalParams);
  }
}

export default ConsoleLogger;
export const logger = new ConsoleLogger({ level: LOG_LEVEL });
// export const warn = new ConsoleLogger({ level: "warn" });
// export const error = new ConsoleLogger({ level: "error" });
